import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import styled from "styled-components"
import {ContentWrapper} from "../components/styles/global-styles"
import {terms} from "../data/legal-data"

const FaqsPage = () => (
  <Layout>
    <Seo title="Terms of Service" />
    <FaqContainer>
      <ContentWrapper isLarge="true">
        <div dangerouslySetInnerHTML={{__html: terms.content}}></div>
      </ContentWrapper>
    </FaqContainer>
  </Layout>
)

export default FaqsPage

const FaqContainer = styled.div`
  background: #000;
  padding: 60px 5rem;
  display: flex;
  justify-content: center;
  color: #fff;

  h1 {
    margin-bottom: 2rem;
  }
  h2 {
    margin-bottom: 1.5rem;
  }
  h3 {
    margin-bottom: 0.5rem;
    margin-top: 1.5rem;
  }

  ol {
    padding: 0 0 0 2rem;
  }
`

